import * as React from "react"
import { Link } from "gatsby"
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";


const NotFoundPage = () => {


  return (
    <MenuContextProvider>
      <Layout PageTitle="No encontrado">
        <HeaderOne />
        <div className="d-flex align-items-center justify-content-center" style={{height:'50vw'}}>
          <div className="row">
            <div
              style={{ marginBottom: "2.5rem", textAlign: "center" }}
            >
              <h5>
                <span style={{ color: "grey" }}>
                  La página solicitada no existe
                </span>
              </h5>
              <Link to="/">
                <span>Volver al inicio</span>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </MenuContextProvider>

    // <div key='full-preloader' id="preloader404" className="preloader">
    //   <div id="status" className="loader">
    //     <span></span>
    //   </div>
    // </div>

  )
}

export default NotFoundPage
